<div class="mat-dialog">
    <div>
        <h3 mat-dialog-title>Upgrade layer version?</h3>
        <mat-dialog-content>
            <p>
                The layer "{{ layerName }}" uses an outdated version of ATT&CK (v{{ vmVersion }}). Do you want to open the workflow to upgrade this
                layer to ATT&CK v{{ currVersion }}?
                <em *ngIf="!this.dataService.isSupported(vmVersion)">
                    This version is not supported by Navigator v{{ navVersion }} and must be upgraded for use.
                </em>
            </p>
        </mat-dialog-content>
    </div>

    <div>
        <mat-dialog-actions>
            <button mat-button (click)="upgradeVersion(true)">Yes</button>
            <button mat-button (click)="upgradeVersion(false)">No</button>
        </mat-dialog-actions>
    </div>
</div>
