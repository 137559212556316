<div
    class="tactic-cell noselect"
    [class.bordered]="!(viewModel.layout.showID || viewModel.layout.showName)"
    [class.mini]="viewModel.layout.layout == 'mini'"
    [matTooltip]="tactic.attackID + ': ' + tactic.name"
    [matTooltipDisabled]="viewModel.layout.showID || viewModel.layout.showName"
    matTooltipPosition="above">
    <ng-container *ngIf="viewModel.layout.showID">{{ tactic.attackID }}</ng-container>
    <div class="id-name-break" *ngIf="viewModel.layout.showID && viewModel.layout.showName"></div>
    <ng-container *ngIf="viewModel.layout.showName">{{ tactic.name }}</ng-container>
</div>
