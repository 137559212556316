<div [style.margin-top]="adjustedHeaderHeight + 'px'" class="header-wrapper">
    <!-- banner -->
    <div *ngIf="hasFeature('header') && bannerContent" class="header">
        <div class="banner">
            <span class="bannerContent" role="alert" [innerHTML]="bannerContent"></span>
            <div class="bannerLinks">
                <a href="https://unveilframework.net">UNVEIL Framework</a>
            </div>
        </div>
    </div>

    <!-- help/changelog -->
    <div class="header help-header">
        <div
            class="help-button"
            [ngClass]="{ 'help-dropdown-open': showHelpDropDown }"
            matTooltip="help"
            (click)="showHelpDropDown = !showHelpDropDown">
            <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 0 24 24" width="24px" fill="#9aa0a6">
                <path d="M0 0h24v24H0z" fill="none" />
                <path
                    d="M11 18h2v-2h-2v2zm1-16C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm0 18c-4.41 0-8-3.59-8-8s3.59-8 8-8 8 3.59 8 8-3.59 8-8 8zm0-14c-2.21 0-4 1.79-4 4h2c0-1.1.9-2 2-2s2 .9 2 2c0 2-3 1.75-3 5h2c0-2.25 3-2.5 3-5 0-2.21-1.79-4-4-4z" />
            </svg>
        </div>
        <div class="dropdown-container" *ngIf="showHelpDropDown && adjustedHeaderHeight >= 0">
            <button (click)="openDialog('help')">help</button>
            <button (click)="openDialog('changelog')">changelog</button>
        </div>
    </div>

    <!-- tabs -->
    <div class="tabs-container scroll">
        <nav mat-tab-nav-bar class="tabs">
            <!-- open tabs -->
            <a
                *ngFor="let tab of layerTabs; let $i = index"
                mat-tab-link
                class="tab-title"
                [class.active]="activeTab === tab"
                (click)="handleTabClick(tab)"
                [active]="activeTab === tab">
                {{ tab.isDataTable ? tab.viewModel.name : tab.title }}
                <span class="tab-close" *ngIf="tab.isCloseable" (click)="closeTab(tab)">
                    <mat-icon>close</mat-icon>
                </span>
                <!-- tab enumerator (for layer operations) -->
                <span
                    class="tab-enumerator"
                    *ngIf="activeTab.showScoreVariables && tab.isDataTable && (!domain || tab.domain === domain) && isAlphabetical(indexToChar($i))">
                    {{ indexToChar($i) }}
                </span>
            </a>
            <!-- create a new tab -->
            <a mat-tab-link class="add-tab" *ngIf="hasFeature('tabs') && layerTabs.length < 12" (click)="newBlankTab()">
                <mat-icon>add</mat-icon>
            </a>
        </nav>
    </div>

    <div *ngIf="layerTabs.length == 0" class="new-tab">
        <!-- should never see this, but in case the config fails to load -->
        <button (click)="newBlankTab()">Start</button>
    </div>
</div>

<ng-template #safariWarning>
    <div class="safari-warning">
        <h3>WARNING</h3>
        <p>
            We’ve detected that you are using the Safari browser. As of Navigator version
            <b>4.3</b>
            , Safari versions 13 and below are not supported due to an unfixable freeze that can occur when selecting a layer tab.
        </p>
        <p>
            We recommend you use Chrome or Firefox instead. You can continue to use the Navigator in Safari (versions 13 and below), but you may lose
            work if the application freezes.
        </p>
        <button mat-button (click)="safariDialogRef.close()">Dismiss</button>
    </div>
</ng-template>

<ng-template #versionWarning let-data>
    <div class="version-warning">
        <div>
            <h3 mat-dialog-title>
                <u>WARNING:</u>
                Outdated Layer
            </h3>
            <mat-dialog-content>
                <p>
                    The uploaded layer version ({{ data.objVersion }}) does not match Navigator's layer version ({{ data.globalVersion }}). The layer
                    configuration may not be fully restored.
                </p>
                <p>View the latest Layer File Format specification and the changelog for more information:</p>
                <p>
                    <a (click)="openDialog('layers')">Layer File Format v{{ data.globalVersion }}</a>
                </p>
                <p>
                    <a (click)="openDialog('changelog')">Changelog</a>
                </p>
            </mat-dialog-content>
        </div>

        <button mat-button mat-dialog-close (click)="versionDialogRef.close()">Dismiss</button>
    </div>
</ng-template>

<!-- new tab template -->
<ng-template [ngIf]="activeTab && !activeTab.isDataTable">
    <div class="new-tab">
        <div class="description">
            <div class="logo">
                <h1><a href="https://github.com/UNVEILFramework/unveil-navigator">UNVEIL Navigator</a></h1>
            </div>
            <div>
                <p>
                    The UNVEIL Navigator is a web-based tool based on MITRE ATT&CK Navigator for annotating and exploring UNVEIL matrices. It can be used to show the frequency of detected techniques on targets, highlight the techniques with the highest potential for being effective against a specific target, visualize HUMINT training coverage, and more.
                </p>
            </div>
            <!-- Help dialog -->
            <div class="help-links-container">
                <button mat-button (click)="openDialog('help')">help</button>
                <button mat-button (click)="openDialog('changelog')">changelog</button>

                <button mat-button (click)="select.open()" class="theme-select-container">
                    <mat-label>theme ▾</mat-label>
                    <mat-select #select class="hide-select" (selectionChange)="handleUserThemeChange($event.value)">
                        <mat-option value="dark">dark</mat-option>
                        <mat-option value="light">light</mat-option>
                        <mat-option value="system">use system</mat-option>
                    </mat-select>
                </button>
            </div>
        </div>
        <mat-accordion class="headers-align">
            <!-- Create new layer interface -->
            <mat-expansion-panel #newlayer>
                <mat-expansion-panel-header>
                    <mat-panel-title>Create new layer</mat-panel-title>
                    <mat-panel-description>Create a new empty layer</mat-panel-description>
                </mat-expansion-panel-header>

                <!-- Domain buttons for latest version -->
                <div class="button-group">
                    <button *ngFor="let domain of latestDomains" mat-raised-button (click)="newLayer(domain.id)">
                        {{ domain.name }}
                    </button>
                </div>

                <!-- More domain/version options -->
                <mat-expansion-panel>
                    <mat-expansion-panel-header>
                        <mat-panel-title>More options</mat-panel-title>
                    </mat-expansion-panel-header>

                    <div class="multi-column multi-column-container">
                        <div class="md-column">
                            <!-- select a version -->
                            <mat-form-field>
                                <mat-select placeholder="Select a version" [(ngModel)]="nVersion" required>
                                    <mat-option [value]="null">none</mat-option>
                                    <mat-option *ngFor="let nVersion of dataService.versions" [value]="nVersion">
                                        {{ nVersion.name }}
                                    </mat-option>
                                </mat-select>
                            </mat-form-field>
                            <!-- select a domain -->
                            <mat-form-field>
                                <mat-select placeholder="Select a domain" [(ngModel)]="nDomain" [disabled]="!nVersion" required>
                                    <mat-option [value]="null">none</mat-option>
                                    <mat-option *ngFor="let nDomain of filterDomains(nVersion)" [value]="nDomain">
                                        {{ nDomain.name }}
                                    </mat-option>
                                </mat-select>
                            </mat-form-field>
                            <!-- note -->
                            <span class="text-deemphasis">
                                *Note: UNVEIL versions prior to v{{ minimumSupportedVersion }} are not supported by Navigator v{{ navVersion }}.
                            </span>
                        </div>
                        <div class="or-column">
                            <b>OR</b>
                        </div>
                        <div class="md-column">
                            <!-- bundle URL -->
                            <mat-form-field>
                                <input type="url" matInput placeholder="Collection or STIX bundle URL" [(ngModel)]="loadData.url" />
                            </mat-form-field>
                            <!-- bundle version number -->
                            <mat-form-field>
                                <input type="number" matInput placeholder="Bundle version number" [(ngModel)]="loadData.version" />
                                <mat-hint>UNVEIL version (e. g. 1.0)</mat-hint>
                            </mat-form-field>
                            <!-- bundle domain identifier -->
                            <mat-form-field>
                                <input type="text" matInput placeholder="Bundle domain" [(ngModel)]="loadData.identifier" />
                                <mat-hint>Domain identifier (e. g. defending-iaas)</mat-hint>
                            </mat-form-field>
                        </div>
                    </div>

                    <div class="multi-column multi-column-container">
                        <div class="md-column">
                            <button style="margin-top: 20px" mat-button [disabled]="!nVersion || !nDomain" (click)="newLayer(nDomain.id)">
                                Create layer from version
                            </button>
                        </div>
                        <div class="sm-column"></div>
                        <div class="md-column">
                            <button
                                style="margin-top: 20px"
                                mat-button
                                [disabled]="!loadData.url || !loadData.version || !loadData.identifier"
                                (click)="newLayerFromURL(loadData)">
                                Create layer from bundle
                            </button>
                        </div>
                    </div>
                </mat-expansion-panel>
            </mat-expansion-panel>

            <!-- Open existing layer interface -->
            <mat-expansion-panel>
                <mat-expansion-panel-header>
                    <mat-panel-title>Open existing layer</mat-panel-title>
                    <mat-panel-description>Load a layer from your computer or a URL</mat-panel-description>
                </mat-expansion-panel-header>

                <div class="multi-column multi-column-container">
                    <div class="md-column">
                        <button mat-raised-button (click)="openUploadPrompt()">Upload from local</button>
                        <input id="uploader" type="file" style="display: none" (change)="loadLayerFromFile()" />
                    </div>
                    <div class="or-column">
                        <b>OR</b>
                    </div>
                    <div class="md-column">
                        <mat-form-field>
                            <input type="text" matInput placeholder="Load from URL" [(ngModel)]="loadURL" />
                            <button
                                mat-button
                                matSuffix
                                mat-icon-button
                                aria-label="go"
                                [disabled]="!loadURL"
                                (click)="loadLayerFromURL(loadURL, true)">
                                >
                            </button>
                        </mat-form-field>
                    </div>
                </div>
            </mat-expansion-panel>

            <!-- Create layer from other layers -->
            <mat-expansion-panel (opened)="activeTab.showScoreVariables = true" (closed)="activeTab.showScoreVariables = false">
                <mat-expansion-panel-header>
                    <mat-panel-title>Create layer from other layers</mat-panel-title>
                    <mat-panel-description>Select layers to inherit properties from</mat-panel-description>
                </mat-expansion-panel-header>

                <div class="multi-column-container">
                    <!-- domain -->
                    <div class="multi-column">
                        <div class="md-column">
                            <mat-form-field>
                                <mat-select placeholder="domain" [(ngModel)]="opSettings.domain" required>
                                    <mat-option *ngFor="let domain of this.dataService.domains" [value]="domain.id">
                                        {{ domain.name }} {{ domain.version.name }}
                                    </mat-option>
                                </mat-select>
                            </mat-form-field>
                        </div>
                        <div class="spacer"></div>
                        <div class="lg-column align-center">
                            <span>Select the domain and version for the new layer. Only layers of the same domain and version can be merged.</span>
                        </div>
                    </div>

                    <!-- score expression -->
                    <div class="multi-column">
                        <div class="md-column">
                            <mat-form-field>
                                <input
                                    type="text"
                                    matInput
                                    placeholder="score expression"
                                    [disabled]="!opSettings.domain"
                                    [(ngModel)]="opSettings.scoreExpression"
                                    (keyup)="opSettings.scoreExpression = opSettings.scoreExpression.toLowerCase()"
                                    style="letter-spacing: 2px" />
                                <mat-hint style="color: red" align="start">{{ getScoreExpressionError() }}</mat-hint>
                            </mat-form-field>
                        </div>
                        <div class="spacer"></div>
                        <div class="lg-column align-center" [ngClass]="{ disabled: !opSettings.domain }">
                            <span>
                                Use constants (numbers) and layer variables (yellow, above) to write an expression for the initial value of scores in
                                the new layer. A full list of supported operations can be found
                                <a href="http://mathjs.org/docs/expressions/syntax.html#operators">here</a>
                                . Leave blank to initialize scores to 0. Here's a list of available layer variables:
                                <ul *ngFor="let tab of layerTabs; let $i = index">
                                    <li
                                        *ngIf="
                                            activeTab.showScoreVariables &&
                                            tab.isDataTable &&
                                            (!opSettings.domain || tab.domain === opSettings.domain) &&
                                            isAlphabetical(indexToChar($i))
                                        ">
                                        <span class="tab-enumerator-highlight">{{ indexToChar($i) }}</span>
                                        ({{ tab.isDataTable ? tab.viewModel.name : tab.title }})
                                    </li>
                                </ul>
                            </span>
                        </div>
                    </div>

                    <!-- gradient -->
                    <div class="multi-column">
                        <div class="md-column">
                            <mat-form-field>
                                <mat-select placeholder="gradient" [(ngModel)]="opSettings.gradientVM" [disabled]="!opSettings.domain">
                                    <mat-option [value]="null">none</mat-option>
                                    <mat-option *ngFor="let vm of getFilteredVMs()" [value]="vm">{{ vm.name }}</mat-option>
                                </mat-select>
                            </mat-form-field>
                        </div>
                        <div class="spacer"></div>
                        <div class="lg-column align-center" [ngClass]="{ disabled: !opSettings.domain }">
                            <span>
                                Select which layer to import the scoring gradient from. Leave blank to initialize with the default scoring gradient.
                            </span>
                        </div>
                    </div>

                    <!-- coloring -->
                    <div class="multi-column">
                        <div class="md-column">
                            <mat-form-field>
                                <mat-select placeholder="coloring" [(ngModel)]="opSettings.coloringVM" [disabled]="!opSettings.domain">
                                    <mat-option [value]="null">none</mat-option>
                                    <mat-option *ngFor="let vm of getFilteredVMs()" [value]="vm">{{ vm.name }}</mat-option>
                                </mat-select>
                            </mat-form-field>
                        </div>
                        <div class="spacer"></div>
                        <div class="lg-column align-center" [ngClass]="{ disabled: !opSettings.domain }">
                            <span>Select which layer to import manually assigned colors from. Leave blank to initialize with no colors.</span>
                        </div>
                    </div>

                    <!-- comments -->
                    <div class="multi-column">
                        <div class="md-column">
                            <mat-form-field>
                                <mat-select placeholder="comments" [(ngModel)]="opSettings.commentVM" [disabled]="!opSettings.domain">
                                    <mat-option [value]="null">none</mat-option>
                                    <mat-option *ngFor="let vm of getFilteredVMs()" [value]="vm">{{ vm.name }}</mat-option>
                                </mat-select>
                            </mat-form-field>
                        </div>
                        <div class="spacer"></div>
                        <div class="lg-column align-center" [ngClass]="{ disabled: !opSettings.domain }">
                            <span>Select which layer to import comments from. Leave blank to initialize with no comments.</span>
                        </div>
                    </div>

                    <!-- links -->
                    <div class="multi-column">
                        <div class="md-column">
                            <mat-form-field>
                                <mat-select placeholder="links" [(ngModel)]="opSettings.linkVM" [disabled]="!opSettings.domain">
                                    <mat-option [value]="null">none</mat-option>
                                    <mat-option *ngFor="let vm of getFilteredVMs()" [value]="vm">{{ vm.name }}</mat-option>
                                </mat-select>
                            </mat-form-field>
                        </div>
                        <div class="spacer"></div>
                        <div class="lg-column align-center" [ngClass]="{ disabled: !opSettings.domain }">
                            <span>Select which layer to import technique links from. Leave blank to initialize without links.</span>
                        </div>
                    </div>

                    <!-- metadata -->
                    <div class="multi-column">
                        <div class="md-column">
                            <mat-form-field>
                                <mat-select placeholder="metadata" [(ngModel)]="opSettings.metadataVM" [disabled]="!opSettings.domain">
                                    <mat-option [value]="null">none</mat-option>
                                    <mat-option *ngFor="let vm of getFilteredVMs()" [value]="vm">{{ vm.name }}</mat-option>
                                </mat-select>
                            </mat-form-field>
                        </div>
                        <div class="spacer"></div>
                        <div class="lg-column align-center" [ngClass]="{ disabled: !opSettings.domain }">
                            <span>Select which layer to import technique metadata from. Leave blank to initialize without metadata.</span>
                        </div>
                    </div>

                    <!-- enabled state -->
                    <div class="multi-column">
                        <div class="md-column">
                            <mat-form-field>
                                <mat-select placeholder="states" [(ngModel)]="opSettings.enabledVM" [disabled]="!opSettings.domain">
                                    <mat-option [value]="null">none</mat-option>
                                    <mat-option *ngFor="let vm of getFilteredVMs()" [value]="vm">{{ vm.name }}</mat-option>
                                </mat-select>
                            </mat-form-field>
                        </div>
                        <div class="spacer"></div>
                        <div class="lg-column align-center" [ngClass]="{ disabled: !opSettings.domain }">
                            <span>Select which layer to import enabled/disabled states from. Leave blank to initialize all to enabled.</span>
                        </div>
                    </div>

                    <!-- filters -->
                    <div class="multi-column">
                        <div class="md-column">
                            <mat-form-field>
                                <mat-select placeholder="filters" [(ngModel)]="opSettings.filterVM" [disabled]="!opSettings.domain">
                                    <mat-option [value]="null">none</mat-option>
                                    <mat-option *ngFor="let vm of getFilteredVMs()" [value]="vm">{{ vm.name }}</mat-option>
                                </mat-select>
                            </mat-form-field>
                        </div>
                        <div class="spacer"></div>
                        <div class="lg-column align-center" [ngClass]="{ disabled: !opSettings.domain }">
                            <span>Select which layer to import filters from. Leave blank to initialize with no filters.</span>
                        </div>
                    </div>

                    <!-- legend -->
                    <div class="multi-column">
                        <div class="md-column">
                            <mat-form-field>
                                <mat-select placeholder="legend" [(ngModel)]="opSettings.legendVM" [disabled]="!opSettings.domain">
                                    <mat-option [value]="null">none</mat-option>
                                    <mat-option *ngFor="let vm of getFilteredVMs()" [value]="vm">{{ vm.name }}</mat-option>
                                </mat-select>
                            </mat-form-field>
                        </div>
                        <div class="spacer"></div>
                        <div class="lg-column align-center" [ngClass]="{ disabled: !opSettings.domain }">
                            <span>Select which layer to import the legend from. Leave blank to initialize with an empty legend.</span>
                        </div>
                    </div>

                    <!-- create -->
                    <button
                        mat-button
                        [disabled]="getScoreExpressionError() || !opSettings.domain"
                        (click)="layerByOperation(); showScoreVariables = false">
                        Create layer
                    </button>
                </div>
            </mat-expansion-panel>

            <!-- Customized Navigator-->
            <mat-expansion-panel>
                <mat-expansion-panel-header>
                    <mat-panel-title>Create customized navigator</mat-panel-title>
                    <mat-panel-description>Create a hyperlink to a customized UNVEIL Navigator</mat-panel-description>
                </mat-expansion-panel-header>

                <div class="multi-column-container">
                    <div class="section">
                        <!-- default layers-->
                        <h2>Default layers</h2>
                        <div class="multi-column left-align">
                            <div class="md-column">
                                <ul class="layer-links">
                                    <li *ngFor="let layerLinkURL of layerLinkURLs; let i = index; trackBy: trackByFunction">
                                        <mat-form-field>
                                            <input type="text" matInput [placeholder]="'default layer ' + (i + 1)" [(ngModel)]="layerLinkURLs[i]" />
                                            <button mat-button matSuffix mat-icon-button aria-label="remove" (click)="removeLayerLink(i)">
                                                <mat-icon>close</mat-icon>
                                            </button>
                                        </mat-form-field>
                                    </li>
                                    <li>
                                        <button mat-raised-button (click)="addLayerLink()">
                                            add {{ layerLinkURLs.length > 0 ? 'another' : 'a' }} default layer
                                        </button>
                                    </li>
                                </ul>
                            </div>
                            <div class="spacer"></div>
                            <div class="lg-column">
                                <span>Enter the URLs of layers hosted on the web. The custom navigator will open these layers by default.</span>
                            </div>
                        </div>
                    </div>

                    <div class="section">
                        <!-- navigator features -->
                        <h2>Navigator features</h2>
                        <ng-container *ngFor="let feature of configService.featureList">
                            <div *ngIf="!feature.subfeatures" class="multi-column left-align feature-row">
                                <div class="md-column align-center">
                                    <mat-checkbox [(ngModel)]="feature.enabled">
                                        {{ feature.name.split('_').join(' ') }}
                                    </mat-checkbox>
                                </div>
                                <div class="spacer"></div>
                                <div class="lg-column">
                                    <span>{{ feature.description }}</span>
                                </div>
                            </div>
                            <!-- subfeatures -->
                            <div *ngIf="feature.subfeatures">
                                <h3 class="capitalize">{{ feature.name.split('_').join(' ') }}</h3>
                                <div class="subfeature-section">
                                    <ng-container *ngFor="let subfeature of feature.subfeatures">
                                        <div class="multi-column left-align feature-row">
                                            <div class="md-column align-center">
                                                <mat-checkbox [(ngModel)]="subfeature.enabled">
                                                    {{ subfeature.name.split('_').join(' ') }}
                                                </mat-checkbox>
                                            </div>
                                            <div class="spacer"></div>
                                            <div class="lg-column">
                                                {{ subfeature.description }}
                                            </div>
                                        </div>
                                    </ng-container>
                                </div>
                            </div>
                        </ng-container>
                    </div>
                </div>

                <!-- Custom Navigator URL -->
                <div style="text-align: center">
                    <mat-form-field id="layerlinkfield">
                        <input
                            id="layerLink"
                            type="text"
                            matInput
                            placeholder="custom navigator URL"
                            (click)="selectLayerLink()"
                            [value]="getLayerLink()"
                            readonly />
                        <button (click)="copyLayerLink()" mat-button matSuffix mat-icon-button aria-label="copy" matTooltip="copy">
                            <mat-icon>content_copy</mat-icon>
                        </button>
                        <mat-hint *ngIf="copiedRecently">copied</mat-hint>
                    </mat-form-field>
                </div>
            </mat-expansion-panel>
        </mat-accordion>
    </div>
</ng-template>

<!-- data table tab template -->
<ng-template [ngIf]="activeTab && activeTab.isDataTable">
    <DataTable
        [viewModel]="activeTab.viewModel"
        [currentDropdown]="dropdownEnabled"
        (dropdownChange)="dropdownEnabled = $event"
        (onScroll)="adjustHeader($event)"></DataTable>
</ng-template>
