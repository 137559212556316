<div class="technique-cell" [ngStyle]="getTechniqueBackground()" [ngClass]="getClass()">
    <div (click)="onClick()" (mouseenter)="highlight()" (mouseleave)="unhighlight()">
        <div class="section">
            <span [style.border-color]="configService.commentColor" [style.color]="getTechniqueTextColor()">
                <b>{{ tactic.name }}</b>
            </span>
        </div>
        <div class="section">
            <span [style.border-color]="configService.commentColor" [style.color]="getTechniqueTextColor()">{{ technique.attackID }}</span>
        </div>
        <div class="section">
            <span [style.border-color]="configService.commentColor" [style.color]="getTechniqueTextColor()">{{ technique.name }}</span>
        </div>
    </div>
</div>
